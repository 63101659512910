import type { FC } from 'react';
import React, { Fragment, useEffect, useState } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead

import { Subscribe } from 'unstated';
import { css, styled, cssMap } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import type { IEditorPreloader } from '@confluence/edit-button';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { BannerStateContainer } from '@confluence/banners';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { ATL_GENERAL, ATL_FOOTER } from '@confluence/web-item-location';
import { PageLayout } from '@confluence/page-layout/entry-points/PageLayout';
import { WebPanelLocation, useATLGeneralWebPanelObserver } from '@confluence/web-panel-location';
import { ReturnToMobile } from '@confluence/return-to-mobile';
import { PerformanceEnd, PERFORMANCE_SUBJECT_mainLayout } from '@confluence/performance';
import { useRightSidebarContext } from '@confluence/page-layout-context';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { fg } from '@confluence/feature-gating';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';

import { BreakoutSSRInlineScript } from './atl-general-breakout-ssr';
import type { MainLayoutQuery_space_lookAndFeel_content_screen as MainLayoutScreenType } from './__types__/MainLayoutQuery';
import {
	ContentBodyWrapper,
	MainContentContainer,
	WebPanelWrapper,
	ContentBody,
	ContentBodyWithContainerType,
	GlobalImagePreviewStyling,
	FixedWebPanelWrapper,
	FixedWebPanelSpacer,
} from './MainLayoutStyledComponents';

const webPanelWrapperStyles = cssMap({
	showPanel: {
		display: 'block',
	},
	hidePanel: {
		display: 'none',
	},
});

const contentBodyWrapperStyles = css({
	marginTop: 0,
	boxSizing: 'border-box',
	position: 'relative',
	width: '100%',
	display: 'flex',
	flex: '0 1 auto',
});

const fixedWebPanelSpacerStyles = css({
	height: 'var(--atl-general-webpanel-height)',
});

const mainContentContainerStyles = cssMap({
	default: {
		width: `calc(100% - ${token('space.025')})`,
		transition: 'width 300ms cubic-bezier(0.2, 0, 0, 1) 0s',
		flexFlow: 'row wrap',
		'@media print': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			width: '100% !important',
		},
		marginLeft: token('space.025'),
	},
	enableRightSidebar: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		width: `calc(
    100vw - var(--leftPanelWidth, 0px) - var(--leftSidebarWidth, 0px) -
      var(--rightPanelWidth, 0px) - var(--rightSidebarWidth, 0px)
  - ${token('space.025')}) !important`,
	},
	nav4Enabled: {
		width: '100%',
		marginLeft: token('space.0'),
	},
	addMinWidth: {
		minWidth: 700,
	},
});

const fixedWebPanelWrapperStyles = cssMap({
	default: {
		display: 'none',
		zIndex: 12, // needs to be above 10 so it covers floating sticky table rows
		width: '100%',
		position: 'fixed',
		backgroundColor: token('elevation.surface'),
		left: 'var(--leftSidebarWidth)',
		maxWidth: `calc( 100vw - var(--leftPanelWidth, 0px) - var(--leftSidebarWidth, 0px) - var(--rightPanelWidth, 0px) - var(--rightSidebarWidth, 0px) )`,
	},
	showPanel: {
		display: 'block',
	},
	isFullWidth: {
		left: '0',
		maxWidth: 'none',
	},
});

type ContentBodyProps = {
	bannerHeight: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ContentBodyCompiled = styled.div<ContentBodyProps>({
	background: token('elevation.surface'),
	boxSizing: 'border-box',
	verticalAlign: 'top',
	minHeight: 'initial',
	padding: 0,
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles
const ContentBodyWithContainerTypeCompiled = styled.div<ContentBodyProps>({
	background: token('elevation.surface'),
	boxSizing: 'border-box',
	verticalAlign: 'top',
	minHeight: 'initial',
	padding: 0,
	width: '100%',
	containerType: 'inline-size',
	containerName: 'content-body-container',
});

type EditorPreloaderType = (config: IEditorPreloader) => JSX.Element | null;

export type MainLayoutComponentProps = {
	children: React.ReactElement;
	enableNavigation?: boolean;
	disableMinWidth?: boolean;
	screen?: MainLayoutScreenType;
	navView?: string;
	editorPreloader?: () => Promise<() => EditorPreloaderType>;
	isViewPage?: boolean;
	isEdit?: boolean;
};

export const MainLayoutComponent: FC<MainLayoutComponentProps> = ({
	navView,
	enableNavigation = true,
	disableMinWidth,
	screen,
	editorPreloader,
	isViewPage = false,
	isEdit,
	children,
}) => {
	const [preloader, setPreloader] = useState<EditorPreloaderType>();
	const {
		shouldShowATLGeneralWebPanel,
		isATLGeneralWebPanelFixed,
		fixedATLGeneralWebPanelRef,
		isFullWidth,
		shouldHidePanels,
	} = useATLGeneralWebPanelObserver(isViewPage);
	const isLivePage = useIsCurrentPageLive();
	const isNav4Enabled = useIsNav4Enabled();

	const rightSidebarContext = useRightSidebarContext();
	const [enableRightSidebarCSS, setEnableRightSidebarCSS] = useState(false);
	const renderServerPlaceholder = useRenderServerPlaceholder();
	const enableBreakoutScript =
		renderServerPlaceholder && fg('confluence_preset_atl_general_height');

	const topElementStyle = {};
	if (screen) {
		for (const key in screen) {
			if (screen.hasOwnProperty(key)) {
				if (key.startsWith('background')) {
					topElementStyle[key] = screen[key];
				}
			}
		}
	}

	useEffect(() => {
		if (rightSidebarContext.getCurrent()) {
			setEnableRightSidebarCSS(true);
		}
	}, [rightSidebarContext]);

	useEffect(() => {
		void setEditorPreloader();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setEditorPreloader = async () => {
		if (editorPreloader) {
			const preloader = await editorPreloader();
			setPreloader(preloader);
		}
	};

	const CompiledWebPanel = isATLGeneralWebPanelFixed ? (
		<Fragment>
			<div
				css={[
					fixedWebPanelWrapperStyles['default'],
					!shouldHidePanels && fixedWebPanelWrapperStyles['showPanel'],
					isFullWidth && fixedWebPanelWrapperStyles['isFullWidth'],
				]}
				ref={fixedATLGeneralWebPanelRef}
				data-test-id="fixed-atl-general-wrapper"
			>
				<WebPanelLocation location={ATL_GENERAL} fetchPolicy="cache-first" />
			</div>
			<div css={fixedWebPanelSpacerStyles} />
		</Fragment>
	) : (
		<div
			css={[
				webPanelWrapperStyles['showPanel'],
				shouldHidePanels && webPanelWrapperStyles['hidePanel'],
			]}
			data-test-id="atl-general-wrapper"
		>
			<WebPanelLocation location={ATL_GENERAL} fetchPolicy="cache-first" />
		</div>
	);

	const EmotionWebPanel = isATLGeneralWebPanelFixed ? (
		<Fragment>
			<FixedWebPanelWrapper
				ref={fixedATLGeneralWebPanelRef}
				isFullWidth={isFullWidth}
				showPanel={!shouldHidePanels}
				data-test-id="fixed-atl-general-wrapper"
				data-connect-container="fixed-atl-general-wrapper"
			>
				<WebPanelLocation location={ATL_GENERAL} fetchPolicy="cache-first" />
			</FixedWebPanelWrapper>
			<FixedWebPanelSpacer />
			{enableBreakoutScript && (
				<BreakoutSSRInlineScript noOpSSRInlineScript={renderServerPlaceholder} />
			)}
		</Fragment>
	) : (
		<WebPanelWrapper showPanel={!shouldHidePanels} data-test-id="atl-general-wrapper">
			<WebPanelLocation location={ATL_GENERAL} fetchPolicy="cache-first" />
		</WebPanelWrapper>
	);

	const AtlGeneralWebPanel = fg('confluence_frontend_main_layout_compiled')
		? CompiledWebPanel
		: EmotionWebPanel;

	const contentCompiled = children ? (
		<ErrorBoundary attribution={Attribution.BACKBONE}>
			<div
				css={[
					mainContentContainerStyles['default'],
					enableRightSidebarCSS && mainContentContainerStyles['enableRightSidebar'],
					!enableRightSidebarCSS &&
						!(disableMinWidth || !enableNavigation) &&
						mainContentContainerStyles['addMinWidth'],
					isNav4Enabled && mainContentContainerStyles['nav4Enabled'],
				]}
				data-test-id="confluence-main-content"
			>
				{shouldShowATLGeneralWebPanel && AtlGeneralWebPanel}
				{/*Banner to allow user to switch from desktop to mobile version*/}
				<ReturnToMobile />

				<>
					<Subscribe to={[BannerStateContainer]}>
						{(bannerState: BannerStateContainer) => (
							<div css={contentBodyWrapperStyles}>
								{fg('confluence_frontend_content-body-container') ? (
									<ContentBodyWithContainerTypeCompiled
										data-test-id="content-body"
										id="content-body"
										bannerHeight={bannerState.getTotalHeight()}
										style={topElementStyle}
									>
										{children}
									</ContentBodyWithContainerTypeCompiled>
								) : (
									<ContentBodyCompiled
										data-test-id="content-body"
										id="content-body"
										bannerHeight={bannerState.getTotalHeight()}
										style={topElementStyle}
									>
										{children}
									</ContentBodyCompiled>
								)}
							</div>
						)}
					</Subscribe>
					{!isEdit && !isLivePage && (
						/* Hides the panel's UI in non-page content types but keeps the content (vendors often use background scripts for this footer extension point) */
						<div
							css={[
								webPanelWrapperStyles['showPanel'],
								shouldHidePanels && webPanelWrapperStyles['hidePanel'],
							]}
							data-test-id="atl-footer-wrapper"
						>
							<WebPanelLocation location={ATL_FOOTER} />
						</div>
					)}
				</>
			</div>
		</ErrorBoundary>
	) : null;

	const contentEmotion = children ? (
		<ErrorBoundary attribution={Attribution.BACKBONE}>
			<MainContentContainer
				isEnableRightSidebarEnabled={enableRightSidebarCSS}
				isNav4Enabled={fg('confluence_nav_4')}
				disableMinWidth={disableMinWidth || !enableNavigation}
				data-test-id="confluence-main-content"
			>
				{shouldShowATLGeneralWebPanel && AtlGeneralWebPanel}
				{/*Banner to allow user to switch from desktop to mobile version*/}
				<ReturnToMobile />

				<>
					<Subscribe to={[BannerStateContainer]}>
						{(bannerState: BannerStateContainer) => (
							<ContentBodyWrapper>
								{fg('confluence_frontend_content-body-container') ? (
									<ContentBodyWithContainerType
										data-test-id="content-body"
										id="content-body"
										topElementStyle={topElementStyle}
										bannerHeight={bannerState.getTotalHeight()}
									>
										{children}
									</ContentBodyWithContainerType>
								) : (
									<ContentBody
										data-test-id="content-body"
										id="content-body"
										topElementStyle={topElementStyle}
										bannerHeight={bannerState.getTotalHeight()}
									>
										{children}
									</ContentBody>
								)}
							</ContentBodyWrapper>
						)}
					</Subscribe>
					{!isEdit && !isLivePage && (
						/* Hides the panel's UI in non-page content types but keeps the content (vendors often use background scripts for this footer extension point) */
						<WebPanelWrapper data-test-id="atl-footer-wrapper" showPanel={!shouldHidePanels}>
							<WebPanelLocation location={ATL_FOOTER} />
						</WebPanelWrapper>
					)}
				</>
			</MainContentContainer>
		</ErrorBoundary>
	) : null;

	const content = fg('confluence_frontend_main_layout_compiled') ? contentCompiled : contentEmotion;

	return (
		<Fragment>
			{enableNavigation ? (
				<PageLayout editorPreloader={preloader} view={navView}>
					{content}
				</PageLayout>
			) : (
				content
			)}
			{fg('confluence_frontend_main_layout_compiled') ? (
				/*
				 * Previously, this used to be a `<Global styles={[…]} />` from Emotion
				 * But in the Compiled migration, there is no alternative and feels like the best path forward for now.
				 */
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles
				<style>{`.cp-container[role='dialog'] {
                        z-index: 350;
                    }`}</style>
			) : (
				<GlobalImagePreviewStyling />
			)}
			<PerformanceEnd subject={PERFORMANCE_SUBJECT_mainLayout} />
		</Fragment>
	);
};
