import React, { useCallback } from 'react';
import { css } from '@compiled/react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { LoomIcon } from '@atlaskit/logo';
import CloseIcon from '@atlaskit/icon/core/close';
import { SpotlightCard } from '@atlaskit/onboarding';
import { Box, Stack, Flex, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { IconButton } from '@atlaskit/button/new';
import Link from '@atlaskit/link';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import Thumbnail from '../assets/Thumbnail.svg';

import type { AutomatedMeetingNotesSpotlightPageSource } from './hooks/useAutomatedMeetingNotesSpotlight';
import { useAutomatedMeetingNotesSpotlight } from './hooks/useAutomatedMeetingNotesSpotlight';

// Unable to swap to css, will need to investigate in the future
const closeIconStyle = xcss({
	position: 'absolute',
	top: token('space.200'),
	right: token('space.200'),
	background: token('elevation.surface'),
	borderRadius: token('space.200'),
});

const closeIconBackgroundStyle = xcss({
	cursor: 'pointer',
	alignItems: 'center',
	top: token('space.300'),
});

const contentStyle = xcss({
	paddingBottom: 'space.300',
});

const spotlightCardWrapperStyle = css({
	position: 'fixed',
	left: token('space.300'),
	bottom: token('space.300'),
	zIndex: 20,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > div': {
		left: token('space.300'),
		backgroundColor: token('elevation.surface.overlay'),
		boxShadow: token('elevation.shadow.overlay'),
		borderRadius: token('space.100'),
		color: token('color.text'),
	},
});

const i18n = defineMessages({
	spotlightTitle: {
		id: 'automated-meeting-notes.spotlight.title',
		defaultMessage: 'Never take another meeting note',
		description: 'Title of the spotlight for Automated Meeting Notes onboarding.',
	},
	spotlightDescription: {
		id: 'automated-meeting-notes.spotlight.description',
		defaultMessage:
			'Track action items, take notes, and send a meeting recap with Loom AI for meetings.',
		description: 'Description of the spotlight for Automated Meeting Notes onboarding.',
	},
	startRecording: {
		id: 'automated-meeting-notes.spotlight.startRecording',
		defaultMessage: 'Start recording',
		description: 'link text to start recording a meeting with Loom AI for meetings.',
	},
	closeButtonLabel: {
		id: 'automated-meeting-notes.spotlight.closeButtonLabel',
		defaultMessage: 'Close',
		description: 'Close Automated Meeting Notes spotlight.',
	},
});

export type AutomatedMeetingNotesSpotlightComponentProps = {
	onClose: () => void;
	pageSource: AutomatedMeetingNotesSpotlightPageSource | null;
};

const loomHref = 'https://www.loom.com/meetings?ignore_redirect=1';

export const AutomatedMeetingNotesSpotlightComponent: React.FC<
	AutomatedMeetingNotesSpotlightComponentProps
> = ({ onClose, pageSource }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onStartRecording = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: pageSource,
				action: 'clicked',
				actionSubject: 'meetingNotesSpotlightCTA',
			},
		}).fire();
	}, [createAnalyticsEvent, pageSource]);

	const onSpotlightDismissed = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: pageSource,
				action: 'dismissed',
				actionSubject: 'meetingNotesSpotlightCTA',
			},
		}).fire();
	}, [createAnalyticsEvent, pageSource]);

	const { formatMessage } = useIntl();
	return (
		<div css={spotlightCardWrapperStyle}>
			<SpotlightCard width={480} image={<img src={Thumbnail} alt="" width="480px" />}>
				<Box xcss={closeIconStyle}>
					<IconButton
						icon={(iconProps) => (
							<Box xcss={closeIconBackgroundStyle}>
								<CloseIcon {...iconProps} />
							</Box>
						)}
						shape="circle"
						appearance="subtle"
						label={formatMessage(i18n.closeButtonLabel)}
						onClick={() => {
							onClose();
							onSpotlightDismissed();
						}}
					/>
				</Box>
				<Flex gap="space.200" xcss={contentStyle}>
					<LoomIcon appearance="brand" />
					<Stack space="space.100">
						<Text color="color.text" size="large">
							<FormattedMessage {...i18n.spotlightTitle} />
						</Text>
						<Text color="color.text.subtle">
							<FormattedMessage {...i18n.spotlightDescription} />
						</Text>
						<Link href={loomHref} target="_blank" onClick={() => onStartRecording()}>
							<FormattedMessage {...i18n.startRecording} />
						</Link>
					</Stack>
				</Flex>
			</SpotlightCard>
		</div>
	);
};

export const AutomatedMeetingNotesSpotlightWrapper = () => {
	const [{ isHidden, pageSourceType }, { onCloseSpotlight }] = useAutomatedMeetingNotesSpotlight();
	return isHidden ? null : (
		<AutomatedMeetingNotesSpotlightComponent
			onClose={onCloseSpotlight}
			pageSource={pageSourceType}
		/>
	);
};
