import { createStore, createHook } from 'react-sweet-state';
import type { StoreActionApi } from 'react-sweet-state';

import { getAGGClient } from '@confluence/graphql';
import { getSessionData } from '@confluence/session-data';
import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';

import { MeetingNotesSettingsQuery } from '../MeetingNotesSettingsQuery.agggraphql';
import type { MeetingNotesSettingsQueryResponse } from '../__types__/MeetingNotesSettingsQueryResponse';

export type SpotlightState = {
	isHidden: boolean;
	meetingNotesAvailable?: boolean;
	meetingNotesEnabled?: boolean;
	pageSourceType: AutomatedMeetingNotesSpotlightPageSource | null;
};

export enum AutomatedMeetingNotesSpotlightPageSource {
	MEETING_NOTES_TEMPLATE = 'MEETING_NOTES_TEMPLATE',
	TEAM_CALENDAR = 'TEAM_CALENDAR',
	PERSONAL_CALENDAR = 'PERSONAL_CALENDAR',
}

export const spotlightActions = {
	onOpenSpotlightIfNeeded:
		(pageSourceType: AutomatedMeetingNotesSpotlightPageSource | null) =>
		async ({ setState, getState }: StoreActionApi<SpotlightState>) => {
			// eslint-disable-next-line prefer-const
			let { meetingNotesAvailable, meetingNotesEnabled, isHidden } = getState();

			if (meetingNotesAvailable === undefined || meetingNotesEnabled === undefined) {
				const meetingSettings = await getMeetingNotesSettings();
				meetingNotesAvailable = meetingSettings.meetingNotesAvailable;
				meetingNotesEnabled = meetingSettings.meetingNotesEnabled;
			}

			const numberTimesShown = Number(localStorage.getItem(keys.AMN_SPOTLIGHT_KEY) || 0);
			const shouldShowSpotlight =
				meetingNotesAvailable && !meetingNotesEnabled && isHidden && numberTimesShown < 2;

			if (shouldShowSpotlight) {
				setState({ isHidden: false, pageSourceType });
				localStorage.setItem(keys.AMN_SPOTLIGHT_KEY, numberTimesShown + 1);
			}
		},
	onCloseSpotlight:
		() =>
		async ({ setState }: StoreActionApi<SpotlightState>) => {
			setState({ isHidden: true });
		},
};

type SpotlightActions = typeof spotlightActions;

const Store = createStore<SpotlightState, SpotlightActions>({
	initialState: {
		isHidden: true,
		pageSourceType: null,
	},
	actions: spotlightActions,
	name: 'automatedMeetingNotesSpotlightState',
});

export const useAutomatedMeetingNotesSpotlight = createHook<SpotlightState, SpotlightActions>(
	Store,
);

const getMeetingNotesSettings = async (): Promise<{
	meetingNotesAvailable: boolean;
	meetingNotesEnabled: boolean;
}> => {
	const { cloudId } = await getSessionData();
	const { data, errors } = await getAGGClient().query<MeetingNotesSettingsQueryResponse>({
		query: MeetingNotesSettingsQuery,
		variables: {
			siteId: cloudId,
		},
	});

	if (errors) {
		throw errors;
	}

	return data.loom_settings;
};
